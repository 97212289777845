import React from "react";

export default function MachineryList() {
  return (
    <>
      <div className="bg-white shadow overflow-hidden sm:rounded-lg flex justify-center my-5">
        <div className="container flex justify-start w-full">
          <div className="flex flex-col grow">
            <div className="border-b border-gray-200  overflow-hidden sm:rounded-lg">
              <div className="py-12 bg-white">
                <div className="w-full mx-auto px-4 sm:px-6 lg:px-8">
                  <div className="lg:text-center">
                    <h2 className="text-base text-indigo-600 font-semibold tracking-wide uppercase">
                      WILLKOMMEN IM
                    </h2>
                    <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                      Indutec Service Manager
                    </p>
                    <p className="mt-4 max-w-2xl text-xl text-gray-500 lg:mx-auto">
                      Ihre zentrale Verwaltungsstelle aller Reinigungsaufträge.
                    </p>
                  </div>

                  <div className="mt-10">
                    <dl className="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10">
                      <div className="relative">
                        <dt>
                          <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="h-6 w-6"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                              strokeWidth={2}
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z"
                              />
                            </svg>
                          </div>
                          <p className="ml-16 text-lg leading-6 font-medium text-gray-900">
                            Ihr Ansprechpartner
                          </p>
                        </dt>
                        <dd className="mt-2 ml-16 text-base text-gray-500">
                          <b>Bärbel Strauch</b>
                        </dd>
                        <dd className="mt-2 ml-16 text-base text-gray-500">
                          <b>E-Mail:</b> Baerbel.Strauch@indutec-holding.de
                        </dd>
                        <dd className="mt-2 ml-16 text-base text-gray-500">
                          <b>Telefon:</b> 0151 440 498 42{" "}
                        </dd>
                        <dt className="mt-10">
                          <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="h-6 w-6"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                              strokeWidth={2}
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z"
                              />
                            </svg>
                          </div>
                          <p className="ml-16 text-lg leading-6 font-medium text-gray-900">
                            Ihr technischer Ansprechpartner vor Ort
                          </p>
                        </dt>
                        <dd className="mt-2 ml-16 text-base text-gray-500">
                          <b>Tolga Emirler</b>
                        </dd>
                        <dd className="mt-2 ml-16 text-base text-gray-500">
                          <b>E-Mail:</b> tolga.emirler@indutec-holding.de
                        </dd>
                        <dd className="mt-2 ml-16 text-base text-gray-500">
                          <b>Telefon:</b> 0174 76 41 797{" "}
                        </dd>
                      </div>
                      <div className="relative">
                        <dt>
                          <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="h-6 w-6"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                              strokeWidth={2}
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M3.055 11H5a2 2 0 012 2v1a2 2 0 002 2 2 2 0 012 2v2.945M8 3.935V5.5A2.5 2.5 0 0010.5 8h.5a2 2 0 012 2 2 2 0 104 0 2 2 0 012-2h1.064M15 20.488V18a2 2 0 012-2h3.064M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                              />
                            </svg>
                          </div>
                          <p className="ml-16 text-lg leading-6 font-medium text-gray-900">
                            Die Indutec-Gruppe
                          </p>
                        </dt>
                        <dd className="mt-2 ml-16 text-base text-gray-500">
                          Mehr über uns und unser Dienstleistungsportfolio
                          erfahren Sie auf unserer Webseite unter{" "}
                          <b>
                            <a
                              href="https://indutec-holding.de"
                              target="_blank"
                            >
                              https://indutec-holding.de
                            </a>
                          </b>
                          .{" "}
                        </dd>
                      </div>
                    </dl>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
