import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import AuthContext from "../../context/AuthContext";
import UserDataContext from "../../context/UserDataContext";

function EditUser() {
  const [email, setEmail] = useState("");
  const [error, setError] = useState(false);
  const [name, setName] = useState("");
  const [kostenstellen, setKostenstellen] = useState([]);
  const [kostenstelleInput, setKostenstelleInput] = useState("");
  const [instandhaltungInput, setInstandhaltungInput] = useState(false);
  const [zeitaufwandInput, setZeitaufwandInput] = useState(false);
  const [isAdminInput, setIsAdminInput] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordVerify, setPasswordVerify] = useState("");
  const { getLoggedIn } = useContext(AuthContext);
  const history = useHistory();
  const { id } = useParams();
  const token = localStorage.getItem("token");
  const { userData } = useContext(UserDataContext);

  async function getUser() {
    const userRes = await axios.get(
      `${process.env.REACT_APP_BACKEND}/auth/${id}`,
      {
        headers: {
          jwttoken: token,
        },
      }
    );
    setEmail(userRes.data.email);
    setName(userRes.data.name);
    setKostenstellen(userRes.data.kostenstellen);
    setInstandhaltungInput(userRes.data.permissions.instandhaltung);
    setZeitaufwandInput(userRes.data.permissions.zeitaufwand);
    setIsAdminInput(userRes.data.isAdmin);

    console.log(userRes.data);
  }

  async function editUser(e) {
    e.preventDefault();

    try {
      const registerData = {
        email,
        name,
        password,
        passwordVerify,
        kostenstellen,
        zeitaufwandInput,
        instandhaltungInput,
        isAdminInput,
      };

      await axios.post(
        `${process.env.REACT_APP_BACKEND}/auth/edit/${id}`,
        registerData,
        {
          headers: {
            jwttoken: token,
          },
        }
      );
      await getLoggedIn();
      history.push("/users/");
    } catch (err) {
      setError(true);
    }
  }

  function addKostenstelle() {
    setKostenstellen([...kostenstellen, kostenstelleInput]);
    setKostenstelleInput("");
  }

  useEffect(() => {
    getUser();
  }, []);

  return (
    <>
      <div className="bg-white shadow overflow-hidden sm:rounded-lg flex justify-between">
        <div className="px-4 py-5 sm:px-6 justify-start lg:w-0 lg:flex-1">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            Benutzer bearbeiten
          </h3>
          <p className="mt-1 text-sm text-gray-500">
            Wenn Sie ihr Passwort nicht ändern möchten lassen Sie dieses Feld
            bitte frei.
          </p>
        </div>
      </div>
      <div className="bg-white  overflow-hidden sm:rounded-lg flex justify-center my-5">
        <div className="container flex justify-start w-full">
          <div className="flex flex-col grow">
            <div className="border-b border-gray-200 shadow overflow-hidden sm:rounded-lg">
              <form onSubmit={editUser}>
                <div className="shadow overflow-hidden sm:rounded-md">
                  <div className="px-4 py-5 bg-white sm:p-6">
                    <div className="grid grid-cols-6 gap-6">
                      <div className="col-span-3">
                        <label className="block text-sm font-medium text-gray-700">
                          Name
                        </label>
                        <input
                          type="string"
                          onChange={(e) => setName(e.target.value)}
                          value={name}
                          className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-lg border-gray-300 rounded-md"
                        />
                      </div>

                      <div className="col-span-3">
                        <label className="block text-sm font-medium text-gray-700">
                          Email Adresse
                        </label>
                        <input
                          type="email"
                          onChange={(e) => setEmail(e.target.value)}
                          value={email}
                          className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-lg border-gray-300 rounded-md"
                        />
                      </div>
                      <div className="col-span-3">
                        <label className="block text-sm font-medium text-gray-700">
                          Neues Passwort
                        </label>
                        <input
                          type="password"
                          onChange={(e) => setPassword(e.target.value)}
                          value={password}
                          placeholder="Passwort bestätigen"
                          className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-lg border-gray-300 rounded-md"
                        />
                        <p className="mt-2 text-sm text-gray-500">
                          Wenn Sie ihr Passwort nicht ändern möchten lassen Sie
                          dieses Feld bitte frei.
                        </p>
                      </div>
                      <div className="col-span-3">
                        <label className="block text-sm font-medium text-gray-700">
                          Neues Passwort bestätigen
                        </label>
                        <input
                          type="password"
                          placeholder="Neues Passwort bestätigen"
                          onChange={(e) => setPasswordVerify(e.target.value)}
                          value={passwordVerify}
                          className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-lg border-gray-300 rounded-md"
                        />
                      </div>
                      <div className="col-span-2">
                        <label className="block text-sm font-medium text-gray-700">
                          Kostenstelle hinzufügen
                        </label>
                        <input
                          type="number"
                          placeholder="Kostenstelle"
                          onChange={(e) => setKostenstelleInput(e.target.value)}
                          value={kostenstelleInput}
                          className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-lg border-gray-300 rounded-md"
                        />
                      </div>
                      <div className="col-span-1">
                        <button
                          onClick={addKostenstelle}
                          type="button"
                          className="ml-2 mt-4 bg-white py-2 px-3 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                          Hinzufügen
                        </button>
                      </div>
                      <div className="col-span-3">
                        <label className="block text-sm font-medium text-gray-700">
                          Kostenstellen
                        </label>
                        {kostenstellen.map((ks, i) => {
                          return (
                            <button
                              onClick={() => {
                                setKostenstellen(
                                  kostenstellen.filter((item) => {
                                    return item !== ks;
                                  })
                                );
                              }}
                              key={ks}
                              type="button"
                              className="mr-2 bg-white py-2 px-3 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                            >
                              {ks}
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="inline-block h-5 w-5"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                  clipRule="evenodd"
                                />
                              </svg>
                            </button>
                          );
                        })}
                      </div>
                      <div className="col-span-3">
                        <fieldset>
                          <legend className="sr-only">By Email</legend>
                          <div
                            className="text-base font-medium text-gray-900"
                            aria-hidden="true"
                          >
                            Berechtigungen
                          </div>
                          <div className="mt-4 space-y-4">
                            <div className="flex items-start">
                              <div className="flex items-center h-5">
                                <input
                                  id="instandhaltung"
                                  type="checkbox"
                                  checked={isAdminInput}
                                  className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                                  onChange={(e) =>
                                    setIsAdminInput(e.target.checked)
                                  }
                                />
                              </div>
                              <div className="ml-3 text-sm">
                                <label
                                  htmlFor="instandhaltung"
                                  className="font-medium text-gray-700"
                                >
                                  Administrator
                                </label>
                                <p className="text-gray-500">
                                  Vorsicht: Ermöglicht das Löschen sämtlicher
                                  Daten!
                                </p>
                              </div>
                            </div>
                            <div className="flex items-start">
                              <div className="flex items-center h-5">
                                <input
                                  id="instandhaltung"
                                  type="checkbox"
                                  checked={instandhaltungInput}
                                  className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                                  onChange={(e) =>
                                    setInstandhaltungInput(e.target.checked)
                                  }
                                />
                              </div>
                              <div className="ml-3 text-sm">
                                <label
                                  htmlFor="instandhaltung"
                                  className="font-medium text-gray-700"
                                >
                                  Instandhaltung
                                </label>
                                <p className="text-gray-500">
                                  Wird benötigt um Position 40 zu bestellen.
                                </p>
                              </div>
                            </div>
                            <div className="flex items-start">
                              <div className="flex items-center h-5">
                                <input
                                  id="zeitaufwand"
                                  type="checkbox"
                                  checked={zeitaufwandInput}
                                  className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                                  onChange={(e) =>
                                    setZeitaufwandInput(e.target.checked)
                                  }
                                />
                              </div>
                              <div className="ml-3 text-sm">
                                <label
                                  htmlFor="candidates"
                                  className="font-medium text-gray-700"
                                >
                                  Zeitaufwand
                                </label>
                                <p className="text-gray-500">
                                  Wird benötigt um Zeitaufwand einzelner
                                  Leistungen einzusehen.
                                </p>
                              </div>
                            </div>
                          </div>
                        </fieldset>
                      </div>
                    </div>
                  </div>
                  <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                    <button
                      type="submit"
                      className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                      Speichern
                    </button>
                    {error && (
                      <div
                        className="flex rounded-md items-center bg-red-500 text-white text-sm font-bold px-4 py-3 my-5"
                        role="alert"
                      >
                        <svg
                          className="fill-current w-6 h-6 mr-3"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                        >
                          <path d="M12.432 0c1.34 0 2.01.912 2.01 1.957 0 1.305-1.164 2.512-2.679 2.512-1.269 0-2.009-.75-1.974-1.99C9.789 1.436 10.67 0 12.432 0zM8.309 20c-1.058 0-1.833-.652-1.093-3.524l1.214-5.092c.211-.814.246-1.141 0-1.141-.317 0-1.689.562-2.502 1.117l-.528-.88c2.572-2.186 5.531-3.467 6.801-3.467 1.057 0 1.233 1.273.705 3.23l-1.391 5.352c-.246.945-.141 1.271.106 1.271.317 0 1.357-.392 2.379-1.207l.6.814C12.098 19.02 9.365 20 8.309 20z" />
                        </svg>
                        <p>
                          Fehler bei der Registrierung. Bitte überprüfen Sie
                          ihre Eingaben oder wenden Sie sich an den Support.
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default EditUser;
