import axios from "axios";
import React, { useEffect, useState, useContext, Fragment } from "react";
import { Link, useParams } from "react-router-dom";
import UserDataContext from "../../context/UserDataContext";
import { Dialog, Transition } from "@headlessui/react";
import { ExclamationIcon } from "@heroicons/react/outline";
import {
  startOfWeek,
  format,
  addDays,
  parseISO,
  isFuture,
  addHours,
  add,
} from "date-fns";
import { mappingServices } from "../../mappings/services";

export default function ServiceList() {
  const [services, setServices] = useState([]);
  const [machinery, setMachinery] = useState({});
  const { userData } = useContext(UserDataContext);
  const [openDelete, setOpenDelete] = useState(false);
  const [anmerkungen, setAnmerkungen] = useState("");
  const [openOrder, setOpenOrder] = useState(false);
  const [deleteService, setDeleteService] = useState({});
  const [orderService, setOrderService] = useState({});
  const [deleteOrder, setDeleteOrder] = useState({});
  const [openStorno, setOpenStorno] = useState(false);
  const { id } = useParams();
  const token = localStorage.getItem("token");

  const currentDate = new Date();
  let startWeek = startOfWeek(currentDate, { weekStartsOn: 1 });
  let filterDate = add(startWeek, { days: 3, hours: 12 });
  let orderDate = addDays(startWeek, 5);
  let orderDateSunday = addDays(startWeek, 6);
  const orderYear = orderDate.getFullYear();

  if (currentDate > filterDate) {
    orderDate = addDays(orderDate, 7);
    orderDateSunday = addDays(orderDateSunday, 7);
  }

  async function getServices() {
    const userRes = await axios.get(
      `${process.env.REACT_APP_BACKEND}/machinery/services/${id}`,
      {
        headers: {
          jwttoken: token,
        },
      }
    );
    setServices(userRes.data);
  }

  async function getMachinery() {
    const userRes = await axios.get(
      `${process.env.REACT_APP_BACKEND}/machinery/${id}`,
      {
        headers: {
          jwttoken: token,
        },
      }
    );
    setMachinery(userRes.data);
  }

  async function deleteServiceCall() {
    await axios.post(
      `${process.env.REACT_APP_BACKEND}/machinery/services/delete/${deleteService.serviceId}`,
      {},
      {
        headers: {
          jwttoken: token,
        },
      }
    );
    setOpenDelete(false);
    setServices(
      services.filter((service) => service._id !== deleteService.serviceId)
    );
  }

  async function addOrderCall(day) {
    if (orderService.ordersAmount && orderService.ordersAmount[orderYear]) {
      orderService.ordersAmount[orderYear] += 1;
    }

    if (orderService.ordersAmount && !orderService.ordersAmount[orderYear]) {
      orderService.ordersAmount[orderYear] = 1;
    }

    if (!orderService.ordersAmount) {
      orderService.ordersAmount = {};
      orderService.ordersAmount[orderYear] = 1;
    }

    const newOrderData = {
      relatedService: orderService.serviceId,
      relatedMachinery: machinery._id,
      creator: userData.id,
      orderDate: addHours(day, 16),
      ordersAmount: orderService.ordersAmount,
      anmerkungen,
    };
    await axios.post(`${process.env.REACT_APP_BACKEND}/orders/`, newOrderData, {
      headers: {
        jwttoken: token,
      },
    });
    getServices();
    setOpenOrder(false);
    setAnmerkungen("");
  }

  async function deleteOrderCall() {
    const lastOrder = await axios.post(
      `${process.env.REACT_APP_BACKEND}/orders/lastorderfromservice/${deleteOrder.serviceId}`,
      {},
      {
        headers: {
          jwttoken: token,
        },
      }
    );

    await axios.post(
      `${process.env.REACT_APP_BACKEND}/orders/delete/${lastOrder.data._id}`,
      { orderData: lastOrder.data },
      {
        headers: {
          jwttoken: token,
        },
      }
    );
    setOpenStorno(false);
    getServices();
  }

  function renderServices() {
    return services.map((service, i) => {
      let status = false;
      let currentYearOrders = 0;

      if (isFuture(parseISO(service.lastOrderDate))) {
        status = true;
      }

      if (service.ordersAmount && service.ordersAmount[orderYear]) {
        currentYearOrders = service.ordersAmount[orderYear];
      }

      if (
        !userData.isAdmin &&
        service.position === "40" &&
        !userData.permissions.instandhaltung
      ) {
        return;
      }

      return (
        <tr key={service._id} className={status ? "bg-green-200" : ""}>
          <td className="px-6 py-4 whitespace-nowrap">
            {status ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth={2}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth={2}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
            )}
          </td>
          <td className="px-6 py-4 whitespace-nowrap">
            {(service.automaticOrder ||
              service.automaticOrderMonthly ||
              service.automaticOrderBiWeeklyEven ||
              service.automaticOrderDaily ||
              service.automaticOrderBiWeeklyUneven) && (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth={2}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
            )}
          </td>
          <td className="px-6 py-4 whitespace-nowrap">
            {service.position} - {mappingServices[service.position]}
          </td>
          <td className="px-6 py-4 whitespace-nowrap">
            {service.price && <p>{service.price} €</p>}{" "}
          </td>
          <td className="px-6 py-4 whitespace-nowrap">
            {currentYearOrders} / {service.amount}
          </td>
          <td className="px-6 py-4 whitespace-nowrap">
            {service.lastOrderDate &&
              service.lastOrderCreator &&
              service.lastOrderCreator.name && (
                <p>
                  {format(Date.parse(service.lastOrderDate), "dd.MM.yyyy")} (
                  {service.lastOrderCreator.name})
                </p>
              )}
          </td>
          <td className="px-6 py-4 inline-block whitespace-nowrap">
            {status && (
              <a
                href="#"
                onClick={() => {
                  setDeleteOrder({
                    serviceId: service._id,
                  });
                  setOpenStorno(true);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="inline-block h-6 w-6 mx-1"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth={2}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </a>
            )}
            {!status && (
              <a
                href="#"
                onClick={() => {
                  setOrderService({
                    name: service.description,
                    serviceId: service._id,
                    ordersAmount: service.ordersAmount,
                  });
                  setOpenOrder(true);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="inline-block h-6 w-6 mx-1"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth={2}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
              </a>
            )}

            {userData && userData.isAdmin && (
              <>
                <Link to={`/equipment/services/edit/${service._id}`}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="inline-block mx-1 h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                    />
                  </svg>
                </Link>
                <a
                  href="#"
                  onClick={() => {
                    setDeleteService({
                      name: service.description,
                      serviceId: service._id,
                    });
                    setOpenDelete(true);
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6 inline-block mx-1"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                    />
                  </svg>
                </a>
              </>
            )}
          </td>
        </tr>
      );
    });
  }

  useEffect(() => {
    getServices();
    getMachinery();
  }, []);

  return (
    <>
      <div className="bg-white shadow overflow-hidden sm:rounded-lg flex justify-between">
        <div className="px-4 py-5 sm:px-6 justify-start lg:w-0 lg:flex-1">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            Leistungen für{" "}
            <b>
              {machinery.kostenstelle}.{machinery.halle}.{machinery.anlage}
            </b>
          </h3>
          <p className="mt-1 max-w-2xl text-sm text-gray-500">
            Bestellungen sind bis <b>Donnerstags 12:00 Uhr</b> für den folgenden
            Samstag möglich.
          </p>
        </div>
        <div className="px-4 py-5 sm:px-6 flex justify-end md:flex-1 lg:w-0">
          {userData && userData.isAdmin && (
            <Link
              to={`/equipment/service/new/${id}`}
              className="ml-8 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6 mr-2"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth={2}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M9 13h6m-3-3v6m5 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
                />
              </svg>
              Leistung hinzufügen
            </Link>
          )}
        </div>
      </div>

      <div className="bg-white w-full  shadow overflow-auto sm:rounded-lg flex justify-between my-5 border-b border-gray-200 shadow  w-full sm:rounded-lg">
        <table className="table-auto w-full overflow-auto divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Status
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Auto
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Position
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Preis / Einheit
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Einheiten / Jahr
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Zuletzt beauftragt für
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Optionen
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {renderServices()}
          </tbody>
        </table>
      </div>
      {openStorno && (
        <Transition.Root show={openStorno} as={Fragment}>
          <Dialog as="div" className="relative z-10" onClose={setOpenStorno}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed z-10 inset-0 overflow-y-auto">
              <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel className="relative bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full">
                    <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                      <div className="sm:flex sm:items-start">
                        <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                          <ExclamationIcon
                            className="h-6 w-6 text-red-600"
                            aria-hidden="true"
                          />
                        </div>
                        <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                          <Dialog.Title
                            as="h3"
                            className="text-lg leading-6 font-medium text-gray-900"
                          >
                            Leistung stornieren
                          </Dialog.Title>
                          <div className="mt-2">
                            <p className="text-sm text-gray-500">
                              Sind Sie sich sicher, dass Sie die Beauftragung
                              der ausgewählten Leistung stornieren möchten?
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                      <button
                        type="button"
                        className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                        onClick={deleteOrderCall}
                      >
                        Löschen
                      </button>
                      <button
                        type="button"
                        className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                        onClick={() => setOpenStorno(false)}
                      >
                        Abbrechen
                      </button>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
      )}
      {openDelete && (
        <Transition.Root show={openDelete} as={Fragment}>
          <Dialog as="div" className="relative z-10" onClose={setOpenDelete}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed z-10 inset-0 overflow-y-auto">
              <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel className="relative bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full">
                    <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                      <div className="sm:flex sm:items-start">
                        <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                          <ExclamationIcon
                            className="h-6 w-6 text-red-600"
                            aria-hidden="true"
                          />
                        </div>
                        <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                          <Dialog.Title
                            as="h3"
                            className="text-lg leading-6 font-medium text-gray-900"
                          >
                            Leistung löschen
                          </Dialog.Title>
                          <div className="mt-2">
                            <p className="text-sm text-gray-500">
                              Sind Sie sich sicher, dass Sie die Leistung{" "}
                              <b>{deleteService.name}</b> inklusive{" "}
                              <b>aller Bestellungen</b> unwiderruflich löschen
                              möchten?
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                      <button
                        type="button"
                        className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                        onClick={deleteServiceCall}
                      >
                        Löschen
                      </button>
                      <button
                        type="button"
                        className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                        onClick={() => setOpenDelete(false)}
                      >
                        Abbrechen
                      </button>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
      )}
      {openOrder && (
        <Transition.Root show={openOrder} as={Fragment}>
          <Dialog as="div" className="relative z-10" onClose={setOpenDelete}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed z-10 inset-0 overflow-y-auto">
              <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel className="relative bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full">
                    <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                      <div className="sm:flex sm:items-start">
                        <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                          <ExclamationIcon
                            className="h-6 w-6 text-red-600"
                            aria-hidden="true"
                          />
                        </div>
                        <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                          <Dialog.Title
                            as="h3"
                            className="text-lg leading-6 font-medium text-gray-900"
                          >
                            Leistung beauftragen
                          </Dialog.Title>
                          <div className="mt-2">
                            <p className="text-sm text-gray-500">
                              Sind Sie sich sicher, dass Sie die Leistung{" "}
                              <b>{orderService.name}</b> für das angezeigte
                              Datum beauftragen möchten?
                            </p>
                            <br />
                            <p className="text-sm text-gray-500">
                              Nächste mögliche Reinigungen:
                              <ul>
                                <li>
                                  <b>
                                    Samstag, {format(orderDate, "dd.MM.yyyy")}{" "}
                                  </b>
                                </li>
                                <li>
                                  <b>
                                    Sonntag,{" "}
                                    {format(orderDateSunday, "dd.MM.yyyy")}{" "}
                                  </b>
                                </li>
                              </ul>
                            </p>
                            <div className="mt-3">
                              <p>
                                <b>Anmerkungen:</b>
                              </p>
                            </div>
                            <textarea
                              id="anmkerungen"
                              name="about"
                              rows={3}
                              onChange={(e) => setAnmerkungen(e.target.value)}
                              className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md"
                              placeholder="Optionale Anmerkungen zur Bestellung"
                              value={anmerkungen}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                      <button
                        onClick={() => addOrderCall(orderDateSunday)}
                        type="button"
                        className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                      >
                        Für Sonntag beauftragen
                      </button>
                      <button
                        onClick={() => addOrderCall(orderDate)}
                        type="button"
                        className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                      >
                        Für Samstag beauftragen
                      </button>
                      <button
                        type="button"
                        className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                        onClick={() => setOpenOrder(false)}
                      >
                        Beauftragung abbrechen
                      </button>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
      )}
    </>
  );
}
