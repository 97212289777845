import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { mappingServices } from "../../mappings/services";

function NewService() {
  const [error, setError] = useState(false);
  const [position, setPosition] = useState("10");
  const [amount, setAmount] = useState("");
  const [price, setPrice] = useState("");
  const [zeitaufwand, setZeitaufwand] = useState("");
  const [automaticOrder, setAutomaticOrder] = useState(false);
  const history = useHistory();
  const [machinery, setMachinery] = useState("");
  const { id } = useParams();
  const token = localStorage.getItem("token");

  async function getMachinery() {
    const userRes = await axios.get(
      `${process.env.REACT_APP_BACKEND}/machinery/${id}`,
      {
        headers: {
          jwttoken: token,
        },
      }
    );
    setMachinery(
      `${userRes.data.kostenstelle}.${userRes.data.halle}.${userRes.data.anlage}`
    );
  }

  async function register(e) {
    e.preventDefault();

    try {
      const registerData = {
        position,
        price,
        amount,
        zeitaufwand,
        automaticOrder,
      };

      await axios.post(
        `${process.env.REACT_APP_BACKEND}/machinery/services/${id}`,
        registerData,
        {
          headers: {
            jwttoken: token,
          },
        }
      );
      history.push(`/equipment/services/${id}`);
    } catch (err) {
      setError(true);
      console.error(err);
    }
  }

  useEffect(() => {
    getMachinery();
  }, []);

  return (
    <>
      <div className="bg-white shadow overflow-hidden sm:rounded-lg flex justify-between">
        <div className="px-4 py-5 sm:px-6 justify-start lg:w-0 lg:flex-1">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            Neue Leistung hinzufügen
          </h3>
          <p className="mt-1 text-sm text-gray-500">
            Hier können Sie für die ausgewählte Maschine eine neue Leistung
            hinzufügen.
          </p>
        </div>
      </div>
      <div className="bg-white overflow-hidden sm:rounded-lg flex justify-center my-5">
        <div className="container flex justify-start w-full">
          <div className="flex flex-col grow">
            <div className="border-b border-gray-200 shadow overflow-hidden sm:rounded-lg">
              <form onSubmit={register}>
                <div className="shadow overflow-hidden sm:rounded-md">
                  <div className="px-4 py-5 bg-white sm:p-6">
                    <div className="grid grid-cols-6 gap-6">
                      <div className="col-span-3">
                        <label className="block text-sm font-medium text-gray-700">
                          Beauftragungsnummer der Maschine
                        </label>
                        <input
                          type="text"
                          value={machinery}
                          disabled
                          className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-lg border-gray-300 rounded-md"
                        />
                      </div>
                      <div className="col-span-3">
                        <label className="block text-sm font-medium text-gray-700">
                          Reinigungsumfang
                        </label>
                        <select
                          name="kw"
                          onChange={(e) => setPosition(e.target.value)}
                          value={position}
                          className="mt-1 pl-3 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        >
                          {Object.keys(mappingServices).map((key, index) => {
                            return (
                              <option value={key}>
                                {key} - {mappingServices[key]}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                      <div className="col-span-3">
                        <label className="block text-sm font-medium text-gray-700">
                          Preis pro Einheit (in Euro)
                        </label>
                        <input
                          type="number"
                          placeholder="Preis pro Einheit"
                          onChange={(e) => setPrice(e.target.value)}
                          value={price}
                          className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-lg border-gray-300 rounded-md"
                        />
                      </div>
                      <div className="col-span-3">
                        <label className="block text-sm font-medium text-gray-700">
                          Anzahl Einheiten
                        </label>
                        <input
                          type="number"
                          min="0"
                          step="1"
                          placeholder="Anzahl Einheiten"
                          onChange={(e) => setAmount(e.target.value)}
                          value={amount}
                          className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-lg border-gray-300 rounded-md"
                        />
                      </div>

                      <div className="col-span-3">
                        <label className="block text-sm font-medium text-gray-700">
                          Zeitaufwand (in Stunden)
                        </label>
                        <input
                          type="number"
                          placeholder="Zeitaufwand"
                          onChange={(e) => setZeitaufwand(e.target.value)}
                          value={zeitaufwand}
                          className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-lg border-gray-300 rounded-md"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                    <button
                      type="submit"
                      className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                      Speichern
                    </button>
                    {error && (
                      <div
                        className="flex rounded-md items-center bg-red-500 text-white text-sm font-bold px-4 py-3 my-5"
                        role="alert"
                      >
                        <svg
                          className="fill-current w-6 h-6 mr-3"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                        >
                          <path d="M12.432 0c1.34 0 2.01.912 2.01 1.957 0 1.305-1.164 2.512-2.679 2.512-1.269 0-2.009-.75-1.974-1.99C9.789 1.436 10.67 0 12.432 0zM8.309 20c-1.058 0-1.833-.652-1.093-3.524l1.214-5.092c.211-.814.246-1.141 0-1.141-.317 0-1.689.562-2.502 1.117l-.528-.88c2.572-2.186 5.531-3.467 6.801-3.467 1.057 0 1.233 1.273.705 3.23l-1.391 5.352c-.246.945-.141 1.271.106 1.271.317 0 1.357-.392 2.379-1.207l.6.814C12.098 19.02 9.365 20 8.309 20z" />
                        </svg>
                        <p>
                          Fehler bei der Übermittlung. Bitte überprüfen Sie ihre
                          Eingaben oder wenden Sie sich an den Support.
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default NewService;
