import axios from "axios";
import React, { useEffect, useState, useContext, Fragment } from "react";
import { Link } from "react-router-dom";
import UserDataContext from "../../context/UserDataContext";
import { Dialog, Transition } from "@headlessui/react";
import { ExclamationIcon } from "@heroicons/react/outline";
import { format } from "date-fns";
import { mappingServices } from "../../mappings/services";

export default function MachineryList() {
  const [machinery, setMachinery] = useState([]);
  const [filteredMachinery, setFilteredMachinery] = useState([]);
  const { userData } = useContext(UserDataContext);
  const [openDelete, setOpenDelete] = useState(false);
  const [deleteMachinery, setDeleteMachinery] = useState({});
  const token = localStorage.getItem("token");
  const [filters, setFilters] = useState({
    typ: "",
    hersteller: "",
    kostenstelle: "",
    anlage: "",
    halle: "",
  });

  const handleFilter = async (obj) => {
    switch (obj.filter) {
      case "TYP":
        let newTypeFilter = filters;
        newTypeFilter.typ = obj.value;
        setFilters(newTypeFilter);
        break;
      case "HERSTELLER":
        let newHerstellerFilter = filters;
        newHerstellerFilter.hersteller = obj.value;
        setFilters(newHerstellerFilter);
        break;
      case "KOSTENSTELLE":
        let newKostenstelleFilter = filters;
        newKostenstelleFilter.kostenstelle = obj.value;
        setFilters(newKostenstelleFilter);
        break;
      case "HALLE":
        let newHalleFilter = filters;
        newHalleFilter.halle = obj.value;
        setFilters(newHalleFilter);
        break;
      case "ANLAGE":
        let newAnlageFilter = filters;
        newAnlageFilter.anlage = obj.value;
        setFilters(newAnlageFilter);
        break;
    }

    let newFilteredMachines = machinery;
    if (filters.typ) {
      newFilteredMachines = newFilteredMachines.filter(function (machine) {
        return machine.maschinenTyp.toUpperCase().includes(filters.typ);
      });
    }
    if (filters.hersteller) {
      newFilteredMachines = newFilteredMachines.filter(function (machine) {
        return machine.maschinenHersteller
          .toUpperCase()
          .includes(filters.hersteller);
      });
    }
    if (filters.kostenstelle) {
      newFilteredMachines = newFilteredMachines.filter(function (machine) {
        return machine.kostenstelle
          .toUpperCase()
          .includes(filters.kostenstelle);
      });
    }
    if (filters.halle) {
      newFilteredMachines = newFilteredMachines.filter(function (machine) {
        return machine.halle.toUpperCase().includes(filters.halle);
      });
    }
    if (filters.anlage) {
      newFilteredMachines = newFilteredMachines.filter(function (machine) {
        return machine.anlage.toUpperCase().includes(filters.anlage);
      });
    }

    setFilteredMachinery(newFilteredMachines);
  };

  async function getMachinery() {
    const userRes = await axios.get(
      `${process.env.REACT_APP_BACKEND}/machinery/`,
      {
        headers: {
          jwttoken: token,
        },
      }
    );
    setMachinery(userRes.data);
    setFilteredMachinery(userRes.data);
  }

  async function deleteMachineryCall() {
    await axios.post(
      `${process.env.REACT_APP_BACKEND}/machinery/delete/${deleteMachinery.machineryId}`,
      {},
      {
        headers: {
          jwttoken: token,
        },
      }
    );
    setOpenDelete(false);
    setMachinery(
      machinery.filter(
        (machinery) => machinery._id !== deleteMachinery.machineryId
      )
    );
  }

  function renderMachinery() {
    return filteredMachinery.map((machine, i) => {
      if (
        userData.kostenstellen.includes(machine.kostenstelle) ||
        userData.isAdmin
      ) {
        return (
          <tr key={machine._id}>
            <td className="px-6 py-4 whitespace-nowrap">
              {machine.maschinenTyp}
            </td>
            <td className="px-6 py-4 whitespace-nowrap">
              {machine.maschinenHersteller}
            </td>
            <td className="px-6 py-4 whitespace-nowrap">
              {machine.kostenstelle}
            </td>
            <td className="px-6 py-4 whitespace-nowrap">{machine.halle}</td>
            <td className="px-6 py-4 whitespace-nowrap">{machine.anlage}</td>
            <td className="px-6 py-4 whitespace-nowrap">
              {machine.lastOrderDate &&
                format(Date.parse(machine.lastOrderDate), "dd.MM.yyyy")}
            </td>
            <td className="px-6 py-4 inline-block whitespace-nowrap">
              <Link to={`/equipment/services/${machine._id}`}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6 inline-block mx-2"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth={2}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M13 5l7 7-7 7M5 5l7 7-7 7"
                  />
                </svg>
              </Link>
              {userData && userData.isAdmin && (
                <>
                  <Link to={`/equipment/edit/${machine._id}`}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="inline-block mx-2 h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      strokeWidth={2}
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                      />
                    </svg>
                  </Link>
                  <a
                    href="#"
                    onClick={() => {
                      setDeleteMachinery({
                        name: machine.description,
                        machineryId: machine._id,
                      });
                      setOpenDelete(true);
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6 inline-block mx-2"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      strokeWidth={2}
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                      />
                    </svg>
                  </a>
                </>
              )}
            </td>
          </tr>
        );
      }
    });
  }

  useEffect(() => {
    getMachinery();
  }, []);

  return (
    <>
      <div className="bg-white shadow overflow-hidden sm:rounded-lg flex justify-between">
        <div className="px-4 py-5 sm:px-6 justify-start lg:w-0 lg:flex-1">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            Bestellung auslösen
          </h3>
          <p className="mt-1 max-w-2xl text-sm text-gray-500">
            Wählen Sie die Maschine aus, für die Sie eine neue Bestellung
            aufgeben möchten.
          </p>
        </div>
        <div className="px-4 py-5 sm:px-6 flex justify-end md:flex-1 lg:w-0">
          {userData && userData.isAdmin && (
            <Link
              to="/equipment/new"
              className="ml-8 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6 mr-2"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth={2}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M9 13h6m-3-3v6m5 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
                />
              </svg>
              Maschine hinzufügen
            </Link>
          )}
        </div>
      </div>
      <div className="bg-white w-full  shadow overflow-auto sm:rounded-lg flex justify-between my-5 border-b border-gray-200 shadow  w-full sm:rounded-lg">
        <table className="table-auto w-full overflow-auto divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Maschinen Typ
                <input
                  type="text"
                  id="filter-typ"
                  className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  onChange={(e) =>
                    handleFilter({
                      filter: "TYP",
                      value: e.target.value.toUpperCase(),
                    })
                  }
                />
              </th>

              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Maschinen Hersteller
                <input
                  type="text"
                  id="filter-typ"
                  className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  onChange={(e) =>
                    handleFilter({
                      filter: "HERSTELLER",
                      value: e.target.value.toUpperCase(),
                    })
                  }
                />
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Kostenstelle
                <input
                  type="text"
                  id="filter-typ"
                  className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  onChange={(e) =>
                    handleFilter({
                      filter: "KOSTENSTELLE",
                      value: e.target.value.toUpperCase(),
                    })
                  }
                />
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Halle
                <input
                  type="text"
                  id="filter-typ"
                  className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  onChange={(e) =>
                    handleFilter({
                      filter: "HALLE",
                      value: e.target.value.toUpperCase(),
                    })
                  }
                />
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Anlage
                <input
                  type="text"
                  id="filter-typ"
                  className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  onChange={(e) =>
                    handleFilter({
                      filter: "ANLAGE",
                      value: e.target.value.toUpperCase(),
                    })
                  }
                />
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Letzte Beauftragung für
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Optionen
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {renderMachinery()}
          </tbody>
        </table>
      </div>
      {openDelete && (
        <Transition.Root show={openDelete} as={Fragment}>
          <Dialog as="div" className="relative z-10" onClose={setOpenDelete}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed z-10 inset-0 overflow-y-auto">
              <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel className="relative bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full">
                    <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                      <div className="sm:flex sm:items-start">
                        <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                          <ExclamationIcon
                            className="h-6 w-6 text-red-600"
                            aria-hidden="true"
                          />
                        </div>
                        <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                          <Dialog.Title
                            as="h3"
                            className="text-lg leading-6 font-medium text-gray-900"
                          >
                            Maschine und alle Leistungen löschen
                          </Dialog.Title>
                          <div className="mt-2">
                            <p className="text-sm text-gray-500">
                              Sind Sie sich sicher, dass Sie die Maschine{" "}
                              <b>{deleteMachinery.name}</b> inklusive alle
                              angelegten <b>Leistungen und Bestellungen </b>
                              unwiderruflich löschen möchten?
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                      <button
                        type="button"
                        className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                        onClick={deleteMachineryCall}
                      >
                        Löschen
                      </button>
                      <button
                        type="button"
                        className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                        onClick={() => setOpenDelete(false)}
                      >
                        Abbrechen
                      </button>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
      )}
    </>
  );
}
