import React, { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import AuthContext from "../../context/AuthContext";
import UserDataContext from "../../context/UserDataContext";
import LogOutBtn from "../auth/LogOutBtn";
import { Popover } from "@headlessui/react";

export default function Navbar() {
  const { loggedIn } = useContext(AuthContext);
  const { userData } = useContext(UserDataContext);

  useEffect(() => {}, [userData]);

  return (
    <Popover className="relative bg-white">
      <div className="max-w-full mx-auto px-4 sm:px-6">
        <div className="flex justify-between items-center border-b-2 border-gray-100 py-6 md:justify-start md:space-x-10">
          <div className="flex justify-start lg:w-0 lg:flex-1">
            <a href="/">
              <img className="h-8 w-auto sm:h-10" src="/logo.png" alt="" />
            </a>
          </div>
          {loggedIn && (
            <>
              <a
                href="/"
                className="text-base font-medium text-gray-500 hover:text-gray-900"
              >
                Start
              </a>
              <a
                href="/equipment"
                className="text-base font-medium text-gray-500 hover:text-gray-900"
              >
                Bestellung auslösen
              </a>
              <a
                href="/historie"
                className="text-base font-medium text-gray-500 hover:text-gray-900"
              >
                Historie
              </a>
            </>
          )}

          {loggedIn && userData && !userData.isAdmin && (
            <>
              <a
                href="/profil"
                className="text-base font-medium text-gray-500 hover:text-gray-900"
              >
                Profil
              </a>
            </>
          )}

          {loggedIn && userData && userData.isAdmin && (
            <>
              <a
                href="/users"
                className="text-base font-medium text-gray-500 hover:text-gray-900"
              >
                Benutzerverwaltung
              </a>
              {/*<a href="/stats" className="text-base font-medium text-gray-500 hover:text-gray-900">*/}
              {/*  Budgetübersicht*/}
              {/*</a>*/}
            </>
          )}

          <div className="hidden md:flex items-center justify-end md:flex-1 lg:w-0">
            {!loggedIn && (
              <Link
                to="/login"
                className="ml-8 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6 mr-2"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth={2}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M11 16l-4-4m0 0l4-4m-4 4h14m-5 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h7a3 3 0 013 3v1"
                  />
                </svg>
                Anmelden
              </Link>
            )}
            {loggedIn === true && (
              <>
                <LogOutBtn />
              </>
            )}
          </div>
        </div>
      </div>
    </Popover>
  );
}
