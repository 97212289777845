import React, { Fragment, useContext, useEffect, useState } from "react";
import axios from "axios";
import UserDataContext from "../../context/UserDataContext";
import { add, format, startOfWeek } from "date-fns";
import { mappingServices } from "../../mappings/services";
import { CSVLink } from "react-csv";
import { Dialog, Transition } from "@headlessui/react";
import { ExclamationIcon } from "@heroicons/react/outline";

export default function CurrentOrders() {
  const currentDate = new Date();
  let startWeek = startOfWeek(currentDate, { weekStartsOn: 1 });
  let filterDate = add(startWeek, { days: 3, hours: 12 });
  const [selectedYear, setSelectedYear] = useState(currentDate.getFullYear());
  const [selectedMonth, setSelectedMonth] = useState(currentDate.getMonth());
  const [orders, setOrders] = useState([]);
  const [csvData, setCsvData] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const { userData } = useContext(UserDataContext);
  const [openDelete, setOpenDelete] = useState(false);
  const [deleteOrder, setDeleteOrder] = useState({});
  const token = localStorage.getItem("token");
  const [filters, setFilters] = useState({
    auftragsnummer: "",
    datum: "",
    auftraggeber: "",
    leistung: "",
    anmerkungen: "",
  });

  async function getOrders() {
    const orderRes = await axios.post(
      `${process.env.REACT_APP_BACKEND}/orders/filter`,
      { selectedYear, selectedMonth },
      { headers: { jwttoken: token } }
    );
    let sortedOrders = orderRes.data;
    sortedOrders.sort(function (a, b) {
      let keyA = new Date(a.orderDate);
      let keyB = new Date(b.orderDate);

      if (keyA < keyB) return 1;
      if (keyA > keyB) return -1;
      return 0;
    });
    setOrders(sortedOrders);
    setFilteredOrders(sortedOrders);
  }

  const handleFilter = async (obj) => {
    switch (obj.filter) {
      case "DATUM":
        let newDateFilter = filters;
        newDateFilter.datum = obj.value;
        setFilters(newDateFilter);
        break;
      case "BEAUFTRAGUNGSNUMMER":
        let newNrFilter = filters;
        newNrFilter.auftragsnummer = obj.value;
        setFilters(newNrFilter);
        break;
      case "AUFTRAGGEBER":
        let newAuftraggeberFilter = filters;
        newAuftraggeberFilter.auftraggeber = obj.value;
        setFilters(newAuftraggeberFilter);
        break;
      case "LEISTUNG":
        let newLeistungFilter = filters;
        newLeistungFilter.leistung = obj.value;
        setFilters(newLeistungFilter);
        break;
      case "KOSTENSTELLE":
        let newKsFilter = filters;
        newKsFilter.kostenstelle = obj.value;
        setFilters(newKsFilter);
        break;
      case "ANMERKUNGEN":
        let newAnFilter = filters;
        newAnFilter.anmerkungen = obj.value;
        setFilters(newAnFilter);
        break;
    }

    console.log(filters);

    let newFilteredOrders = orders;
    if (filters.datum) {
      newFilteredOrders = newFilteredOrders.filter(function (order) {
        return `${format(Date.parse(order.orderDate), "dd.MM.yy")}`.includes(
          filters.datum
        );
      });
    }
    if (filters.auftragsnummer) {
      newFilteredOrders = newFilteredOrders.filter(function (order) {
        return `${order.relatedMachinery.kostenstelle}.${order.relatedMachinery.halle}.${order.relatedMachinery.anlage}.${order.relatedService.position}`.includes(
          filters.auftragsnummer
        );
      });
    }
    if (filters.leistung) {
      newFilteredOrders = newFilteredOrders.filter(function (order) {
        return mappingServices[order.relatedService.position]
          .toUpperCase()
          .includes(filters.leistung);
      });
    }
    if (filters.auftraggeber) {
      newFilteredOrders = newFilteredOrders.filter(function (order) {
        return order.creator.name.toUpperCase().includes(filters.auftraggeber);
      });
    }
    if (filters.kostenstelle) {
      newFilteredOrders = newFilteredOrders.filter(function (order) {
        return order.relatedMachinery.kostenstelle.includes(
          filters.kostenstelle
        );
      });
    }
    if (filters.anmerkungen) {
      newFilteredOrders = newFilteredOrders.filter(function (order) {
        return order.anmerkungen.toUpperCase().includes(filters.anmerkungen);
      });
    }

    setFilteredOrders(newFilteredOrders);
  };

  const generateCsvData = () => {
    const csvData = [
      [
        "Kostenstelle",
        "Beauftragungsnummer",
        "Leistung",
        "Hersteller",
        "Typ",
        "Bezeichnung",
        "Halle",
        "Preis",
        "Abgerufenen Einheiten",
        "Geplante Einheiten / Jahr",
        "Datum",
        "Auftraggeber",
        "Anmerkungen",
      ],
    ];

    if (userData && (userData.isAdmin || userData.permissions.zeitaufwand)) {
      csvData[0].push("Zeitaufwand");
    }

    filteredOrders.forEach((element) => {
      let newLine = [
        element.relatedMachinery.kostenstelle,
        `${element.relatedMachinery.kostenstelle}.${element.relatedMachinery.halle}.${element.relatedMachinery.anlage}.${element.relatedService.position}`,
        mappingServices[element.relatedService.position],
        element.relatedMachinery.maschinenHersteller,
        element.relatedMachinery.maschinenTyp,
        element.relatedMachinery.anlage,
        element.relatedMachinery.halle,
        String(element.relatedService.price).replace(".", ","),
        element.relatedService.ordersAmount[selectedYear],
        element.relatedService.amount,
        format(Date.parse(element.orderDate), "dd.MM.yy"),
        element.creator.name,
        element.anmerkungen,
      ];
      if (userData && (userData.isAdmin || userData.permissions.zeitaufwand)) {
        newLine.push(
          String(element.relatedService.zeitaufwand).replace(".", ",")
        );
      }
      csvData.push(newLine);
    });
    setCsvData(csvData);
  };

  async function deleteOrderCall() {
    await axios.post(
      `${process.env.REACT_APP_BACKEND}/orders/delete/${deleteOrder.orderData._id}`,
      deleteOrder,
      {
        headers: {
          jwttoken: token,
        },
      }
    );
    setOpenDelete(false);
    setFilteredOrders(
      filteredOrders.filter((order) => order._id !== deleteOrder.orderData._id)
    );
  }

  useEffect(() => {
    getOrders();
  }, [selectedYear, selectedMonth]);

  return (
    <>
      <div className="bg-white shadow overflow-hidden sm:rounded-lg flex justify-between">
        <div className="px-4 py-5 sm:px-6 justify-start lg:w-0 lg:flex-1">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            Historie
          </h3>
          <p className="mt-1 max-w-2xl text-sm text-gray-500">
            Bitte beachten Sie, dass Bestellungen für den kommenden Samstag nur
            bis <b>Donnerstags 12:00 Uhr</b> möglich sind.
          </p>
        </div>
        <div className="px-4 py-5 sm:px-6 flex justify-end md:flex-1 lg:w-0">
          <div className="col-span-6 sm:col-span-3 mr-3">
            <select
              name="kw"
              onChange={(e) => setSelectedMonth(e.target.value)}
              value={selectedMonth}
              className="mt-1 pl-3 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            >
              <option value="0">Januar</option>
              <option value="1">Februar</option>
              <option value="2">März</option>
              <option value="3">April</option>
              <option value="4">Mai</option>
              <option value="5">Juni</option>
              <option value="6">Juli</option>
              <option value="7">August</option>
              <option value="8">September</option>
              <option value="9">Oktober</option>
              <option value="10">November</option>
              <option value="11">Dezember</option>
            </select>
          </div>
          <div className="col-span-6 sm:col-span-3 mr-3">
            <select
              name="kw"
              onChange={(e) => setSelectedYear(e.target.value)}
              value={selectedYear}
              className="mt-1 pl-3 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            >
              <option value={currentDate.getFullYear()}>
                {currentDate.getFullYear()}
              </option>
              <option value="2022">2022</option>
              <option value="2024">2024</option>
              <option value="2025">2025</option>
            </select>
          </div>
          <CSVLink
            onClick={generateCsvData}
            filename={"service-manager-export.csv"}
            data={csvData}
            className="ml-8 inline-flex items-center justify-center px-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6 mr-2"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
              />
            </svg>
            Export
          </CSVLink>
        </div>
      </div>
      <div className="bg-white w-full  shadow overflow-auto sm:rounded-lg flex justify-between my-5 border-b border-gray-200 shadow  w-full sm:rounded-lg">
        <table className="table-auto w-full overflow-auto divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Kosten- Stelle
                <input
                  type="text"
                  id="filter-kostenstelle"
                  className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  onChange={(e) =>
                    handleFilter({
                      filter: "KOSTENSTELLE",
                      value: e.target.value.toUpperCase(),
                    })
                  }
                />
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Beauftragungsnummer
                <input
                  type="text"
                  id="filter-auftragsnummer"
                  className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  onChange={(e) =>
                    handleFilter({
                      filter: "BEAUFTRAGUNGSNUMMER",
                      value: e.target.value.toUpperCase(),
                    })
                  }
                />
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Leistung
                <input
                  type="text"
                  id="filter-auftragsnummer"
                  className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  onChange={(e) =>
                    handleFilter({
                      filter: "LEISTUNG",
                      value: e.target.value.toUpperCase(),
                    })
                  }
                />
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Preis
              </th>
              {userData &&
                (userData.isAdmin || userData.permissions.zeitaufwand) && (
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Zeitaufwand
                  </th>
                )}
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Einheiten / Jahr
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Datum
                <input
                  type="text"
                  id="filter-datum"
                  className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  onChange={(e) =>
                    handleFilter({ filter: "DATUM", value: e.target.value })
                  }
                />
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Auftraggeber
                <input
                  type="text"
                  id="filter-auftragsnummer"
                  className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  onChange={(e) =>
                    handleFilter({
                      filter: "AUFTRAGGEBER",
                      value: e.target.value.toUpperCase(),
                    })
                  }
                />
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Anmerkungen
                <input
                  type="text"
                  id="filter-anmerkungen"
                  className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  onChange={(e) =>
                    handleFilter({
                      filter: "ANMERKUNGEN",
                      value: e.target.value.toUpperCase(),
                    })
                  }
                />
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Optionen
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {filteredOrders.map((order, i) => {
              let deleteable = false;

              if (
                new Date(order.orderDate) > filterDate ||
                (userData && userData.isAdmin)
              ) {
                deleteable = true;
              }

              if (
                userData.kostenstellen.includes(
                  order.relatedMachinery.kostenstelle
                ) ||
                userData.isAdmin
              ) {
                return (
                  <tr key={order._id}>
                    <td className="px-6 py-4 whitespace-nowrap">
                      {order.relatedMachinery.kostenstelle}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      {order.relatedMachinery.kostenstelle}.
                      {order.relatedMachinery.halle}.
                      {order.relatedMachinery.anlage}.
                      {order.relatedService.position}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      {mappingServices[order.relatedService.position]}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      {order.relatedService.price} €
                    </td>
                    {userData &&
                      (userData.isAdmin ||
                        userData.permissions.zeitaufwand) && (
                        <td className="px-6 py-4 whitespace-nowrap">
                          {order.relatedService.zeitaufwand}h
                        </td>
                      )}
                    <td className="px-6 py-4 whitespace-nowrap">
                      {order.relatedService.ordersAmount[selectedYear]} /{" "}
                      {order.relatedService.amount}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      {format(Date.parse(order.orderDate), "dd.MM.yy")}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      {order.creator.name}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      {order.anmerkungen}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      {deleteable && (
                        <a
                          href="#"
                          onClick={() => {
                            setDeleteOrder({
                              orderData: order,
                            });
                            setOpenDelete(true);
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="inline-block h-6 w-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            strokeWidth={2}
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M6 18L18 6M6 6l12 12"
                            />
                          </svg>
                        </a>
                      )}
                    </td>
                  </tr>
                );
              }
            })}
          </tbody>
        </table>
      </div>
      {openDelete && (
        <Transition.Root show={openDelete} as={Fragment}>
          <Dialog as="div" className="relative z-10" onClose={setOpenDelete}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed z-10 inset-0 overflow-y-auto">
              <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel className="relative bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full">
                    <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                      <div className="sm:flex sm:items-start">
                        <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                          <ExclamationIcon
                            className="h-6 w-6 text-red-600"
                            aria-hidden="true"
                          />
                        </div>
                        <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                          <Dialog.Title
                            as="h3"
                            className="text-lg leading-6 font-medium text-gray-900"
                          >
                            Leistung stornieren
                          </Dialog.Title>
                          <div className="mt-2">
                            <p className="text-sm text-gray-500">
                              Sind Sie sich sicher, dass Sie die Beauftragung
                              der ausgewählten Leistung stornieren möchten?
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                      <button
                        type="button"
                        className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                        onClick={deleteOrderCall}
                      >
                        Löschen
                      </button>
                      <button
                        type="button"
                        className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                        onClick={() => setOpenDelete(false)}
                      >
                        Abbrechen
                      </button>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
      )}
    </>
  );
}
