import React from "react";
import Router from "./Router";
import axios from "axios";
import { AuthContextProvider } from "./context/AuthContext";
import { UserDataContextProvider } from "./context/UserDataContext";

axios.defaults.withCredentials = true;

function App() {
  return (
    <AuthContextProvider>
        <UserDataContextProvider>
            <Router />
        </UserDataContextProvider>
    </AuthContextProvider>
  );
}

export default App;
