import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import UserDataContext from "../../context/UserDataContext";
import { format } from "date-fns";

export default function Stats() {
  const currentDate = new Date();
  const [services, setServices] = useState([]);
  const [selectedYear, setSelectedYear] = useState(currentDate.getFullYear());
  const [orders, setOrders] = useState([]);
  const { userData } = useContext(UserDataContext);
  const token = localStorage.getItem("token");

  useEffect(() => {}, [selectedYear, userData]);

  if (!userData || !userData.isAdmin) {
    return <div>404 - Fehlende Berechtigung!</div>;
  }

  return (
    <>
      <div className="bg-white shadow overflow-hidden sm:rounded-lg flex justify-between">
        <div className="px-4 py-5 sm:px-6 justify-start lg:w-0 lg:flex-1">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            Budgetübersicht
          </h3>
          <p className="mt-1 max-w-2xl text-sm text-gray-500">
            Verfügbares Budget: <b>1234567,89 €</b>. Davon bereits abgerufen:{" "}
            <b>3456,78 €</b>
          </p>
        </div>
        <div className="px-4 py-5 sm:px-6 flex justify-end md:flex-1 lg:w-0">
          <div className="col-span-6 sm:col-span-3 mr-3">
            <select
              name="kw"
              onChange={(e) => setSelectedYear(e.target.value)}
              value={selectedYear}
              className="mt-1 pl-3 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            >
              <option value={currentDate.getFullYear()}>
                {currentDate.getFullYear()}
              </option>
              <option value="2023">2023</option>
              <option value="2024">2024</option>
            </select>
          </div>
        </div>
      </div>

      <div className="bg-white shadow overflow-auto sm:rounded-lg flex justify-between my-5">
        <div className="container flex justify-start w-full">
          <div className="flex flex-col grow">
            <div className="border-b border-gray-200 shadow  sm:rounded-lg">
              <table className="min-w-full overflow-auto divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Kostenstelle
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Preis / Einheit
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Einheiten / Jahr
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Gesamltvolumen
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Abgerufenes Volumen von
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {orders.map((order, i) => {
                    if (
                      userData.kostenstellen.includes(
                        order.relatedMachinery.fachbereich
                      ) ||
                      userData.isAdmin
                    ) {
                      return (
                        <tr key={order._id}>
                          <td className="px-6 py-4 whitespace-nowrap">
                            {order.relatedService.description}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            {order.relatedMachinery.description}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            {order.relatedService.position}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            {order.relatedService.price} €
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            {order.relatedService.ordersAmount[selectedYear]} /{" "}
                            {order.relatedService.amount}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            {format(Date.parse(order.orderDate), "dd.MM.yy")}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            {order.creator.name}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">TBD</td>
                        </tr>
                      );
                    }
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
