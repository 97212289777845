import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import AuthContext from "../../context/AuthContext";
import UserDataContext from "../../context/UserDataContext";

function Profil() {
  const [email, setEmail] = useState("");
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [name, setName] = useState("");
  const [kostenstellen, setKostenstellen] = useState([]);
  const [password, setPassword] = useState("");
  const [passwordVerify, setPasswordVerify] = useState("");
  const { getLoggedIn } = useContext(AuthContext);
  const history = useHistory();
  const token = localStorage.getItem("token");
  const { userData } = useContext(UserDataContext);

  async function getUser() {
    if (userData) {
      setEmail(userData.email);
      setName(userData.name);
      setKostenstellen(userData.kostenstellen);
    }
  }

  async function editUser(e) {
    e.preventDefault();

    try {
      const registerData = {
        email,
        name,
        password,
        passwordVerify,
      };

      await axios.post(
        `${process.env.REACT_APP_BACKEND}/auth/profil/${userData.id}`,
        registerData,
        {
          headers: {
            jwttoken: token,
          },
        }
      );
      await getLoggedIn();
      setSuccess(true);
      setTimeout(() => {
        setSuccess(false);
      }, 4000);
    } catch (err) {
      setError(true);
    }
  }

  useEffect(() => {
    getUser();
  }, [userData]);

  return (
    <>
      <div className="bg-white shadow overflow-hidden sm:rounded-lg flex justify-between">
        <div className="px-4 py-5 sm:px-6 justify-start lg:w-0 lg:flex-1">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            Profil bearbeiten
          </h3>
          <p className="mt-1 text-sm text-gray-500">
            Hier können Sie Ihre Profildaten einsehen und bearbeiten.
          </p>
        </div>
      </div>
      <div className="bg-white  overflow-hidden sm:rounded-lg flex justify-center my-5">
        <div className="container flex justify-start w-full">
          <div className="flex flex-col grow">
            <div className="border-b border-gray-200 shadow overflow-hidden sm:rounded-lg">
              <form onSubmit={editUser}>
                <div className="shadow overflow-hidden sm:rounded-md">
                  <div className="px-4 py-5 bg-white sm:p-6">
                    <div className="grid grid-cols-6 gap-6">
                      <div className="col-span-3">
                        <label className="block text-sm font-medium text-gray-700">
                          Name
                        </label>
                        <input
                          type="string"
                          onChange={(e) => setName(e.target.value)}
                          value={name}
                          className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-lg border-gray-300 rounded-md"
                        />
                      </div>

                      <div className="col-span-3">
                        <label className="block text-sm font-medium text-gray-700">
                          Email Adresse
                        </label>
                        <input
                          type="email"
                          onChange={(e) => setEmail(e.target.value)}
                          value={email}
                          className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-lg border-gray-300 rounded-md"
                        />
                      </div>
                      <div className="col-span-3">
                        <label className="block text-sm font-medium text-gray-700">
                          Neues Passwort
                        </label>
                        <input
                          type="password"
                          onChange={(e) => setPassword(e.target.value)}
                          value={password}
                          placeholder="Passwort bestätigen"
                          className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-lg border-gray-300 rounded-md"
                        />
                        <p className="mt-2 text-sm text-gray-500">
                          Wenn Sie ihr Passwort nicht ändern möchten lassen Sie
                          dieses Feld bitte frei.
                        </p>
                      </div>
                      <div className="col-span-3">
                        <label className="block text-sm font-medium text-gray-700">
                          Neues Passwort bestätigen
                        </label>
                        <input
                          type="password"
                          placeholder="Neues Passwort bestätigen"
                          onChange={(e) => setPasswordVerify(e.target.value)}
                          value={passwordVerify}
                          className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-lg border-gray-300 rounded-md"
                        />
                      </div>
                      <div className="col-span-3">
                        <label className="block text-sm font-medium text-gray-700">
                          Zugeordnete Kostenstellen
                        </label>
                        {kostenstellen.map((ks, i) => {
                          return (
                            <button
                              key={ks}
                              type="button"
                              className="mr-2 bg-white py-2 px-3 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                            >
                              {ks}
                            </button>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                  <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                    <button
                      type="submit"
                      className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                      Speichern
                    </button>
                    {error && (
                      <div
                        className="flex rounded-md items-center bg-red-500 text-white text-sm font-bold px-4 py-3 my-5"
                        role="alert"
                      >
                        <svg
                          className="fill-current w-6 h-6 mr-3"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                        >
                          <path d="M12.432 0c1.34 0 2.01.912 2.01 1.957 0 1.305-1.164 2.512-2.679 2.512-1.269 0-2.009-.75-1.974-1.99C9.789 1.436 10.67 0 12.432 0zM8.309 20c-1.058 0-1.833-.652-1.093-3.524l1.214-5.092c.211-.814.246-1.141 0-1.141-.317 0-1.689.562-2.502 1.117l-.528-.88c2.572-2.186 5.531-3.467 6.801-3.467 1.057 0 1.233 1.273.705 3.23l-1.391 5.352c-.246.945-.141 1.271.106 1.271.317 0 1.357-.392 2.379-1.207l.6.814C12.098 19.02 9.365 20 8.309 20z" />
                        </svg>
                        <p>
                          Fehler bei der Registrierung. Bitte überprüfen Sie
                          ihre Eingaben oder wenden Sie sich an den Support.
                        </p>
                      </div>
                    )}
                    {success && (
                      <div
                        className="flex rounded-md items-center bg-green-500 text-white text-sm font-bold px-4 py-3 my-5"
                        role="alert"
                      >
                        <svg
                          className="fill-current w-6 h-6 mr-3"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                        >
                          <path d="M12.432 0c1.34 0 2.01.912 2.01 1.957 0 1.305-1.164 2.512-2.679 2.512-1.269 0-2.009-.75-1.974-1.99C9.789 1.436 10.67 0 12.432 0zM8.309 20c-1.058 0-1.833-.652-1.093-3.524l1.214-5.092c.211-.814.246-1.141 0-1.141-.317 0-1.689.562-2.502 1.117l-.528-.88c2.572-2.186 5.531-3.467 6.801-3.467 1.057 0 1.233 1.273.705 3.23l-1.391 5.352c-.246.945-.141 1.271.106 1.271.317 0 1.357-.392 2.379-1.207l.6.814C12.098 19.02 9.365 20 8.309 20z" />
                        </svg>
                        <p>
                          Ihre Benutzerdaten wurden erfolgreich gespeichert!
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Profil;
