import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import UserDataContext from "../../context/UserDataContext";
import { mappingServices } from "../../mappings/services";

function EditService() {
  const [error, setError] = useState(false);
  const [position, setPosition] = useState("");
  const [zeitaufwand, setZeitaufwand] = useState("");
  const [price, setPrice] = useState("");
  const [amount, setAmount] = useState("");
  const [machineryId, setMachineryId] = useState("");
  const [machinery, setMachinery] = useState("");
  const [automaticOrder, setAutomaticOrder] = useState(false);
  const [automaticOrderDaily, setAutomaticOrderDaily] = useState(false);
  const [automaticOrderWeekly2Times, setAutomaticOrderWeekly2Times] = useState(false);
  const [automaticOrderWeekly3Times, setAutomaticOrderWeekly3Times] = useState(false);
  const [automaticOrderBiWeeklyEven, setAutomaticOrderBiWeeklyEven] =
    useState(false);
  const [automaticOrderBiWeeklyUneven, setAutomaticOrderBiWeeklyUneven] =
    useState(false);
  const [automaticOrderMonthly, setAutomaticOrderMonthly] = useState(false);
  const history = useHistory();
  const { id } = useParams();
  const token = localStorage.getItem("token");
  const { userData } = useContext(UserDataContext);

  async function getService() {
    const serviceRes = await axios.get(
      `${process.env.REACT_APP_BACKEND}/machinery/service/${id}`,
      {
        headers: {
          jwttoken: token,
        },
      }
    );
    setPosition(serviceRes.data.position);
    setPrice(serviceRes.data.price);
    setAmount(serviceRes.data.amount);
    setMachineryId(serviceRes.data.machinery);
    setZeitaufwand(serviceRes.data.zeitaufwand);
    setAutomaticOrder(serviceRes.data.automaticOrder);
    setAutomaticOrderBiWeeklyEven(serviceRes.data.automaticOrderBiWeeklyEven);
    setAutomaticOrderBiWeeklyUneven(
      serviceRes.data.automaticOrderBiWeeklyUneven
    );
    setAutomaticOrderMonthly(serviceRes.data.automaticOrderMonthly);
    setAutomaticOrderDaily(serviceRes.data.automaticOrderDaily);
    setAutomaticOrderWeekly2Times(serviceRes.data.automaticOrderWeekly2Times);
    setAutomaticOrderWeekly3Times(serviceRes.data.automaticOrderWeekly3Times);

  }

  async function getMachinery() {
    const userRes = await axios.get(
      `${process.env.REACT_APP_BACKEND}/machinery/${machineryId}`,
      {
        headers: {
          jwttoken: token,
        },
      }
    );
    setMachinery(
      `${userRes.data.kostenstelle}.${userRes.data.halle}.${userRes.data.anlage}`
    );
  }

  async function register(e) {
    e.preventDefault();

    try {
      const registerData = {
        position,
        price,
        amount,
        zeitaufwand,
        automaticOrder,
        automaticOrderBiWeeklyEven,
        automaticOrderBiWeeklyUneven,
        automaticOrderMonthly,
        automaticOrderDaily,
        automaticOrderWeekly2Times,
        automaticOrderWeekly3Times,
      };

      await axios.post(
        `${process.env.REACT_APP_BACKEND}/machinery/service/edit/${id}`,
        registerData,
        {
          headers: {
            jwttoken: token,
          },
        }
      );
      history.push(`/equipment/services/${machineryId}`);
    } catch (err) {
      setError(true);
      console.error(err);
    }
  }

  useEffect(() => {
    getService();
    getMachinery();
  }, [machineryId]);

  return (
    <>
      <div className="bg-white shadow overflow-hidden sm:rounded-lg flex justify-between">
        <div className="px-4 py-5 sm:px-6 justify-start lg:w-0 lg:flex-1">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            Leistung bearbeiten
          </h3>
          <p className="mt-1 text-sm text-gray-500">
            Hier können Sie die ausgewählte Leistung bearbeiten.
          </p>
        </div>
      </div>
      <div className="bg-white overflow-hidden sm:rounded-lg flex justify-center my-5">
        <div className="container flex justify-start w-full">
          <div className="flex flex-col grow">
            <div className="border-b border-gray-200 shadow overflow-hidden sm:rounded-lg">
              <form onSubmit={register}>
                <div className="shadow overflow-hidden sm:rounded-md">
                  <div className="px-4 py-5 bg-white sm:p-6">
                    <div className="grid grid-cols-6 gap-6">
                      <div className="col-span-3">
                        <label className="block text-sm font-medium text-gray-700">
                          Beauftragungsnummer der Maschine
                        </label>
                        <input
                          type="text"
                          value={machinery}
                          disabled
                          className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-lg border-gray-300 rounded-md"
                        />
                      </div>
                      <div className="col-span-3">
                        <label className="block text-sm font-medium text-gray-700">
                          Reinigungsumfang
                        </label>
                        <select
                          name="kw"
                          onChange={(e) => setPosition(e.target.value)}
                          value={position}
                          className="mt-1 pl-3 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        >
                          {Object.keys(mappingServices).map((key, index) => {
                            return (
                              <option value={key}>
                                {key} - {mappingServices[key]}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                      <div className="col-span-2">
                        <label className="block text-sm font-medium text-gray-700">
                          Einheiten pro Jahr
                        </label>
                        <input
                          type="number"
                          min="0"
                          step="1"
                          placeholder="Einheiten pro Jahr"
                          onChange={(e) => setAmount(e.target.value)}
                          value={amount}
                          className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-lg border-gray-300 rounded-md"
                        />
                      </div>
                      <div className="col-span-2">
                        <label className="block text-sm font-medium text-gray-700">
                          Preis pro Einheit (in Euro)
                        </label>
                        <input
                          type="number"
                          placeholder="Preis pro Einheit"
                          onChange={(e) => setPrice(e.target.value)}
                          value={price}
                          className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-lg border-gray-300 rounded-md"
                        />
                      </div>
                      <div className="col-span-2">
                        <label className="block text-sm font-medium text-gray-700">
                          Zeitaufwand (in Stunden)
                        </label>
                        <input
                          type="number"
                          placeholder="Zeitaufwand"
                          onChange={(e) => setZeitaufwand(e.target.value)}
                          value={zeitaufwand}
                          className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-lg border-gray-300 rounded-md"
                        />
                      </div>
                      <div className="col-span-6">
                        <fieldset>
                          <h2>
                            Automatische Bestellung (Bitte nur eins auswählen)
                          </h2>
                          <div className="mt-4 space-y-4">
                            <div className="flex items-start">
                              <div className="flex items-center h-5">
                                <input
                                  id="instandhaltung"
                                  type="checkbox"
                                  checked={automaticOrder}
                                  className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                                  onChange={(e) =>
                                    setAutomaticOrder(e.target.checked)
                                  }
                                />
                              </div>
                              <div className="ml-3 text-sm">
                                <label
                                  htmlFor="instandhaltung"
                                  className="font-medium text-gray-700"
                                >
                                  Wöchentlich
                                </label>
                                <p className="text-gray-500">
                                  Diese Position soll automatisch für jede Woche
                                  beauftragt werden.
                                </p>
                              </div>
                              <div className="flex items-center h-5">
                                <input
                                  id="instandhaltung"
                                  type="checkbox"
                                  checked={automaticOrderBiWeeklyEven}
                                  className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                                  onChange={(e) =>
                                    setAutomaticOrderBiWeeklyEven(
                                      e.target.checked
                                    )
                                  }
                                />
                              </div>
                              <div className="ml-3 text-sm">
                                <label
                                  htmlFor="instandhaltung"
                                  className="font-medium text-gray-700"
                                >
                                  Zwei-Wöchentlich (gerade KWs)
                                </label>
                                <p className="text-gray-500">
                                  Diese Position soll automatisch für jede 2.
                                  gerade KW beauftragt werden.
                                </p>
                              </div>
                              <div className="flex items-center h-5">
                                <input
                                  id="instandhaltung"
                                  type="checkbox"
                                  checked={automaticOrderBiWeeklyUneven}
                                  className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                                  onChange={(e) =>
                                    setAutomaticOrderBiWeeklyUneven(
                                      e.target.checked
                                    )
                                  }
                                />
                              </div>
                              <div className="ml-3 text-sm">
                                <label
                                  htmlFor="instandhaltung"
                                  className="font-medium text-gray-700"
                                >
                                  Zwei-Wöchentlich (ungerade KWs)
                                </label>
                                <p className="text-gray-500">
                                  Diese Position soll automatisch für jede 2.
                                  ungerade KW beauftragt werden.
                                </p>
                              </div>
                              <div className="flex items-center h-5">
                                <input
                                  id="instandhaltung"
                                  type="checkbox"
                                  checked={automaticOrderMonthly}
                                  className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                                  onChange={(e) =>
                                    setAutomaticOrderMonthly(e.target.checked)
                                  }
                                />
                              </div>
                              <div className="ml-3 text-sm">
                                <label
                                  htmlFor="instandhaltung"
                                  className="font-medium text-gray-700"
                                >
                                  Monatlich
                                </label>
                                <p className="text-gray-500">
                                  Diese Position soll automatisch für jede 1.
                                  Woche im Monat beauftragt werden.
                                </p>
                              </div>
                              <div className="flex items-center h-5">
                                <input
                                  id="instandhaltung"
                                  type="checkbox"
                                  checked={automaticOrderDaily}
                                  className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                                  onChange={(e) =>
                                    setAutomaticOrderDaily(e.target.checked)
                                  }
                                />
                              </div>
                              <div className="ml-3 text-sm">
                                <label
                                  htmlFor="instandhaltung"
                                  className="font-medium text-gray-700"
                                >
                                  Täglich (Mo. - Fr.)
                                </label>
                                <p className="text-gray-500">
                                  Diese Position wird jeden Tag
                                  <b> unter der Woche</b> bestellt und sollte
                                  daher nicht händisch bestellt werden.
                                </p>
                              </div>
                              <div className="flex items-center h-5">
                                <input
                                  id="instandhaltung"
                                  type="checkbox"
                                  checked={automaticOrderWeekly2Times}
                                  className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                                  onChange={(e) =>
                                    setAutomaticOrderWeekly2Times(e.target.checked)
                                  }
                                />
                              </div>
                              <div className="ml-3 text-sm">
                                <label
                                  htmlFor="instandhaltung"
                                  className="font-medium text-gray-700"
                                >
                                  2x Wöchentlich (Di., Do.)
                                </label>
                                <p className="text-gray-500">
                                  Diese Position wird jeden Dienstag und Donnerstag bestellt.
                                </p>
                              </div>
                              <div className="flex items-center h-5">
                                <input
                                  id="instandhaltung"
                                  type="checkbox"
                                  checked={automaticOrderWeekly3Times}
                                  className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                                  onChange={(e) =>
                                    setAutomaticOrderWeekly3Times(e.target.checked)
                                  }
                                />
                              </div>
                              <div className="ml-3 text-sm">
                                <label
                                  htmlFor="instandhaltung"
                                  className="font-medium text-gray-700"
                                >
                                  3x Wöchentlich (Mo., Mi., Fr.)
                                </label>
                                <p className="text-gray-500">
                                Diese Position wird jeden Montag, Mittwoch und Freitag bestellt.
                                </p>
                              </div>
                            </div>
                          </div>
                        </fieldset>
                      </div>
                    </div>
                  </div>
                  <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                    <button
                      type="submit"
                      className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                      Speichern
                    </button>
                    {error && (
                      <div
                        className="flex rounded-md items-center bg-red-500 text-white text-sm font-bold px-4 py-3 my-5"
                        role="alert"
                      >
                        <svg
                          className="fill-current w-6 h-6 mr-3"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                        >
                          <path d="M12.432 0c1.34 0 2.01.912 2.01 1.957 0 1.305-1.164 2.512-2.679 2.512-1.269 0-2.009-.75-1.974-1.99C9.789 1.436 10.67 0 12.432 0zM8.309 20c-1.058 0-1.833-.652-1.093-3.524l1.214-5.092c.211-.814.246-1.141 0-1.141-.317 0-1.689.562-2.502 1.117l-.528-.88c2.572-2.186 5.531-3.467 6.801-3.467 1.057 0 1.233 1.273.705 3.23l-1.391 5.352c-.246.945-.141 1.271.106 1.271.317 0 1.357-.392 2.379-1.207l.6.814C12.098 19.02 9.365 20 8.309 20z" />
                        </svg>
                        <p>
                          Fehler bei der Übermittlung. Bitte überprüfen Sie ihre
                          Eingaben oder wenden Sie sich an den Support.
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default EditService;
