export const mappingServices = {
  10: "Reinigung der Maschinenaußenfläche (Standardreinigung)",
  10.1: "Beschreibung folgt",
  10.2: "Beschreibung folgt",
  10.3: "Beschreibung folgt",
  10.4: "Beschreibung folgt",
  "10-01": "Beschreibung folgt",
  20: "Reinigung des Podests",
  20.1: "Beschreibung folgt",
  20.2: "Beschreibung folgt",
  20.3: "Beschreibung folgt",
  30: "Reinigung des Maschinenumfeldes",
  30.3: "Beschreibung folgt",
  30.4: "Beschreibung folgt",
  "30-01": "Beschreibung folgt",
  40: "Geplante Servicereinigungen (Maschinenwartung/-inspektion)",
  "40-01": "Beschreibung folgt",
  "40-02": "Beschreibung folgt",
  "40-03": "Beschreibung folgt",
  "40-04": "Beschreibung folgt",
  "40-05": "Beschreibung folgt",
  "40-06": "Beschreibung folgt",
  "40-07": "Beschreibung folgt",
  "40-08": "Beschreibung folgt",
  "40-09": "Beschreibung folgt",
  "40-10": "Beschreibung folgt",
  "40-11": "Beschreibung folgt",
  "40-12": "Beschreibung folgt",
  "40-13": "Beschreibung folgt",
  "40-14": "Beschreibung folgt",
  "40-15": "Beschreibung folgt",
  50: "Reinigung von Räumen",
  60: "Reinigung von Wegen und Halleneingängen",
  70: "Reinigung von Lagereinrichtungen/-flächen",
  80: "Reinigung von Produktionsflächen und Werkstätten",
  80.1: "Müll leeren",
  80.2: "Beschreibung folgt",
  80.3: "Beschreibung folgt",
  80.4: "Waschbecken reinigen",
  80.5: "Beschreibung folgt",
  80.6: "Beschreibung folgt",
  80.7: "Beschreibung folgt",
  80.8: "Beschreibung folgt",
  90: "HD",
  100: "KSS-Filter reinigen",
  "L01.01": "Bodenreinigung Halle und Bodenfolie neu verlegen (Sa.)",
  "L01.02": "Bodenreinigung Halle (Mi.)",
  L02: "Bodenrost Reinigung  und Folienverlegung",
  "L02.01": "Bodenrostreinigung und Folienverlegung – Konservierungsplatz",
  "L02.02": "Bodenrostreinigung und Folienverlegung – Spritzplätze",
  "L05.01": "thermisches Entlacken Gitterrost des Lackiergestells (pro Stück)",
  "L05.02": "manuelles Entlacken Lackiergestell (pro Stück)",
  "L05.03": "Lackierwägen reinigen (absaugen) - pro Stück",
  L06: "Absaugwände, Trockner, Lagerraum komplett reinigen und Außen mit 4-fach-Folie bekleben",
  L07: "Hallendecke inkl. Verglasung, Fenster streifenfrei reinigen --- inkl. OG",
  L08: "Türen und Tore: Innen/Außen abreinigen, Verglasungen streifenfrei reinigen --- inkl. OG",
  L09: "Lufttechnische Anlagen inkl. Schaltschränke reinigen",
  L10: "Zu- und Abluftkanäle Außen reinigen",
  L12: "Lackierung Halle Bodenreinigung",
  L14: "Flächen waagrecht und senkrecht reinigen, Spinnweben entfernen",
  L15: "Halle Außen: Türe, Tore und Fenster beidseitig reinigen",
  L16: "6 Tore innen und außen reinigen",
  G1: "Reinigung der Rohrleitungen unter der Hallendecke",
  G2: "Hallenbereich Spinnweben entfernen",
  G3: "Reinigung Türen und Schnelllauftor",
  K1: "Reinigung Kollektoren",
  VF: "Vließfilter tauschen",
  Ö1: "Ölwanne Reinigen",
  R1: "Reinigung Rollbänder innen und außen",
  30.1: "Riffelblechboden reinigen",
  30.2: "Bodenbeschichtung reinigen",
  GT1: "Produktionsfläche / Anlage",
  GT2: "Produktionsfläche / Begehbare Fläche Tisch (zu ASL)/ Produktionsfläche / Begehbare Fläche Tisch (bei Magazin)",
  GT3: "Produktionsfläche / Begehbare Fläche Anlage",
  GT4: "Boden mit Schraffierung im hinteren Bereich (Absturzkennzeichnung ins Maschinenfundament)",
  GT5: "Produktionsfläche / Hydrostatik / KSS Tanks (hinter der Anl.) / Produktionsfläche / Hydrostatik / KSS Tanks (im Keller der Anlage)",
  GT6: "Produktionsfläche / Hydrostatikraum",
  GT7: "Produktionsfläche / Podest Schaltschrank",
  GT8: "Produktionsfläche / Podest Traverse (Zugang beschränkt)",
  GT9: "Produktionsfläche / Podest beim dem Bedienkorb",
  GT10: "Produktionsfläche / Podest Schaltschrank & Hydraulik (Zugang beschränkt)",
  GT11: "Produktionsfläche / Anlage / alle zugänglichen Teleskopabdeckungen komplett gestreckt, Querbalken oben",
  GT12: "Restliche Teleskopabdeckungen u. RAM, Querbalken Unterseite u. Portalsäulen innen",
  GT13: "Produktionsfläche / Anlage Werkzeug- & Kopfmagazin",
  GT14: "Produktionsfläche / Anlage Werkzeugmagazin",
  GT15: "Werkzeug: Werkzeugbereich, -roboter, Winkelkopfshuttle u. -innenraum inklusive Boden bei Schienen",
  GT16: "KSS Anlage und Späneförderer im Nebengebäude",
  GT17: "Produktionsfläche / Stellfläche um die Anlage 119536",
  GT18: "Produktionsfläche / Stellfläche und Fläche der 106732 (Anreisplatte)",
  GT19: "Produktionsfläche / Stellfläche um die Anlage 100496 (Spänepresse)",
  GT20: "Produktionsfläche / Stellfläche um die Anlage 85956",
  GT21: "Produktionsfläche / Stellfläche und Wendeplatz für Gestelle",
  GT22: "Produktionsfläche / Stellfläche vor der Anlage 92082 es sind 259m² nicht begehbar",
  GT23: "Produktionsfläche / Anlagenumfeld",
  GT24: "Produktionsfläche / Begehbare Fläche Tisch zu ASL",
  GT25: "Produktionsfläche / Hydrostatik / KSS Tanks im Keller der Anlage",
  GT26: "Produktionsfläche / Stellfläche um die Anlage 72571",
  GT27: "Produktionsfläche / Werkzeugbereitstellung / es wurden 22m² wegen Schränken abgezogen",
  GT28: "Produktionsfläche / Stellfläche um die Anlage 72623",
  GT29: "Produktionsfläche / Q-Abteilung-Büro",
  GT30: "Produktionsfläche / Stellfläche",
  GT31: "Produktionsfläche / Medienraum Anbau für Anlage 104375",
  GT32: "Produktionsfläche / Stellfläche um die Anlage 129386",
  40.1: "Schaltschrank",
  40.2: "Umfeld Schaltschrank",
  40.3: "Außenbereich Schaltschrank",
  40.4: "Außen- und Innenraum auskehren",
  40.5: "Rohre und Leitungen",
  40.6: "Filteranlage",
  40.7: "Arbeitspodest",
  40.8: "Außenhaut, Verrohrung",
  40.9: "Hydraulikaggregat",
  "40.10": "Rohre Hydraulikaggregat",
  40.11: "Wände, Dach, Absaugrohre",
  40.12: "Wände, Absaugrohre",
  40.13: "Wände, Rohre, Absaugrohre",
  40.14: "Bodenfläche innen",
  40.15: "Wände, Rohre, Absaugrohre, Innen",
  40.16: "Auf- und Abgang",
  40.17: "Wände, Rohre, Zugänge, Dach",
  40.18: "Stützen, Verstrebungen",
  40.19: "Stützen",
  "40.20": "Stützen, Verstrebungen, Trichter",
  40.21: "Stützen, Verstrebungen, Dach",
  40.22: "Stützen, Verstrebungen, Innen",
  40.23: "Stützen, Träger, Verstrebungen",
  40.24: "Brückenkran, Schaltschrank",
  40.25: "Kranbahn, Laufstege, Auflage oben",
  40.26: "Kabinendach außen",
  40.27: "Schutzwände",
  40.28: "Transportwagen 5 St.",
  40.29: "Kabine außen EG",
  "40.30": "Putzkabine, Wände und obendrauf",
  40.31: "Absaughaube",
  40.32: "Lüftungskanal",
  40.33: "Filter Mischer 6 mit Trichter",
  40.34: "Filteranlage Schaltschrank; Innen",
  40.35: "Strahlanlage außen und innen, Wägen",
  40.36: "Strahlanlage außen und innen Schaltschrank",
  40.37: "Kernschießmaschine, Bedienpult, Trichter, Schaltschrank",
  40.38: "Kernschießmaschine, Bedienpult, Hängebahn",
  40.39: "Kernschießmaschine, Schaltschrank, Podest, Hängebahn",
  "40.40": "Rundbahn, Kranbahn",
  40.41: "Sandversorgung, Schaltschrank",
  40.42: "Wendeeinrichtung",
  40.43: "Manipulator 3 Stück",
  40.44: "Mischer",
  40.45: "Aufgang, Laufsteg, Auflage oben",
  40.46: "Aufgang hoch 3 Ebenen 6m, Laufsteg, Auflage oben",
  40.47: "Trichter, Aufgang, Auflage oben",
  40.48: "Laufbahn, Auflagen",
  40.49: "Laufstege, Auflage oben",
  "40.50": "Laufbahn, Auflagen, Auf- und Abgang",
  40.51: "Kellerzugang 9m tief besenrein",
  40.52: "Mischerfahrbahn, Laufstege, Auflage oben",
  40.53: "Sichtschutz, Lampen, Umfeld 2m",
  40.54: "Elektroraum komplett reinigen",
  40.55: "Produktionsfläche / Unzugängliche Bereiche der Anlage",
  40.56: "Zwischendeck und -ebenen oberhalb des Meisterbüros",
  40.57: "Glühofen innen und außen",
  40.58: "Filteranlage Aufgang, Dach",
  40.59: "Schaltschrank EG",
  "40.60": "Mischer",
  40.61: "Schlichtestation",
  PR: "Reinigung Palettenpools mit Rüstplatz",
  101: "Filter der Ölnebenabsaugungen tauschen",
  VB: "Reinigung Bodens im Verfahrbereich",
  BS: "Reinigung Bodensumpfwanne Rüstplatz 3",
  102: "Filter in Absaugwand tauschen",
  RI: "Roboterputzzelle innen",
  SL: "Reinigung Schnelllauftor",
  BO: "Bodenbereich reinigen und Sensor vor Trocknungsofen",
  I1: "Innenraum reinigen",
  F1: "Scheiben reinigen innen und außen",
  BM1: "Bodenmatten reinigen",
  M1: "Reinigung Montagebänder",
  WM: "Wekzeugmagazin reinigen",
  RP: "Rüstplatz (Palettenrüstplatz) reinigen",
  Boden: "Bodenreinigung neue Beschichtung - Kellenberger",
  RA: "Reinigung Arm",
  RZ: "Reinigung Zelle mit Umfeld",
  EK : "Entgradkabine Komplettreinigung",
  KI: "Kunststoffinlays für Pumpenstößel am Waschplatz reinigen",
  FK: "Filterkörbe von der Beizanlage reinigen",
  VFS: "Vließfilter zuschneiden",
  WZW: "Werkzeugwagen reinigen - Stück",
  SC: "Spänecontainer reinigen - Stück",
  MF: "Metallfilter reinigen",
};
